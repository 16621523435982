import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
 import "./Privacy.css"
class Privacy extends Component {
    navigateTo = (type) => {
        console.log("navigateToHome", this.props)
        this.props.history.push('/' + type)
    }   

    state = {
        tfn: null
    }

    componentDidMount() {
        window.scrollTo(0,0)
    }

    componentWillUnmount() {
        window.scrollTo(0,0)
    }

    setUrl = (url) => {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set("tfn", url);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + searchParams.toString();
        window.history.pushState({ path: newurl }, "", newurl);
        window.scrollTo(0, 0)
        this.setState({ tfn: url })
    }

    getTfn = (tfn) => {
        let temp = ""
        for (let i = 0; i < tfn.length; i++) {
            if (tfn[i] !== "-") {
                temp = temp + tfn[i]
            }
        }
        return "tel:+" + temp
    }

    getTfnFormat = (tfn) => {
        let _tfn = ""
        // if(tfn.length === 14){
        tfn = tfn.split('-')
        _tfn = "(" + tfn[1] + ") " + tfn[2] + "-" + tfn[3]
        // }
        return _tfn
    }

    render() {
        const urlParams = new URLSearchParams(window.location.search);
        let tfn = urlParams.get("tfn");
        return (
            <>
             <body className="home page-template-default page page-id-6 wp-custom-logo bridge-core-2.0.8  qode-title-hidden qode_grid_1300 footer_responsive_adv qode-content-sidebar-responsive qode-overridden-elementors-fonts qode-child-theme-ver-1.1.9.2022.07.25 qode-theme-ver-19.5 qode-theme-bridge qode_advanced_footer_responsive_1000 wpb-js-composer js-comp-ver-6.9.0 vc_responsive elementor-default elementor-template-full-width elementor-kit-1771 elementor-page elementor-page-6">
            
              <div
          data-elementor-type="header"
          data-elementor-id="37130"
          className="elementor elementor-37130 elementor-location-header"
        >
          <header
            className="elementor-section elementor-top-section elementor-element elementor-element-afdff74 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default parallax_section_no qode_elementor_container_no"
            data-id="afdff74"
            data-element_type="section"
            data-settings='{"background_background":"classNameic","sticky":"top","sticky_on":["desktop","tablet","mobile"],"sticky_offset":0,"sticky_effects_offset":0}'
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-58edd96"
                data-id="58edd96"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-ebb3272 nolazy elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
                    data-id="ebb3272"
                    data-element_type="widget"
                    data-widget_type="theme-site-logo.default"
                  >
                    <div className="elementor-widget-container">
                      <a href="/" >
                        {/* <img width="252" height="40" className="attachment-full size-full" alt="Cable Internet In My Area" loading="lazy" /> */}
                        <p className="footer-para"> <span style={{fontSize:"32px"}}>Linking Flux Company</span> <br/>
                        <span style={{fontSize:"12px"}}> Authorized Reseller of TV, Internet & Phone Services</span></p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fe63449"
                data-id="fe63449"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-43c0836 elementor-nav-menu--stretch elementor-nav-menu__align-center elementor-nav-menu--dropdown-tablet elementor-nav-menu__text-align-aside elementor-nav-menu--toggle elementor-nav-menu--burger elementor-widget elementor-widget-nav-menu"
                    data-id="43c0836"
                    data-element_type="widget"
                    data-widget_type="nav-menu.default"
                  >
                    <div className="elementor-widget-container">
                      <nav
                        migration_allowed="1"
                        migrated="0"
                        role="navigation"
                        className="elementor-nav-menu--main elementor-nav-menu__container elementor-nav-menu--layout-horizontal e--pointer-none"
                      >
                        <ul id="menu-1-43c0836" className="elementor-nav-menu">
                          <li className="elementor-button menu-item menu-item-type-custom menu-item-object-custom menu-item-690">
                        
                            <a
                              onClick={this.handleClick}
                            href={tfn === null ? "tel:+18552000985" : this.getTfn(tfn)}
                              // href="tel:+18552000985"
                              className="elementor-item">
                                     {tfn === null ? "(855) 200-0985" : this.getTfnFormat(tfn)}
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobno">
              <div className="elementor-button-wrapper">
                <a
                 onClick={this.handleClick}
                href={tfn === null ? "tel:+18552000985" : this.getTfn(tfn)}
                  // href="tel:+18552000985"
                  className="elementor-button-link elementor-button elementor-size-xs"
                  role="button">
                  <span className="elementor-button-content-wrapper">
                    <span className="elementor-button-text">
                      
                     {tfn === null ? "(855) 200-0985" : this.getTfnFormat(tfn)}
                      
                    </span>
                  </span>
                </a>
              </div>
            </div>
          </header>
        </div>
              <div class="privacyPage">
              <div
                  className="home page-template page-template-elementor_canvas page page-id-243 et_pb_button_helper_class et_non_fixed_nav et_show_nav et_primary_nav_dropdown_animation_fade et_secondary_nav_dropdown_animation_fade et_header_style_left et_pb_footer_columns4 et_cover_background et_pb_gutter osx et_pb_gutters3 et_right_sidebar et_divi_theme et-db et_minified_js et_minified_css elementor-default elementor-template-canvas elementor-kit-6 elementor-page elementor-page-243">
                  <div data-elementor-type="wp-page" data-elementor-id="243" className="elementor elementor-243">
                      <section className="elementor-section elementor-top-section elementor-element elementor-element-785733d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                          data-id="785733d" data-element_type="section">
                          <div className="elementor-container elementor-column-gap-default">
                              <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-cbb469e"
                                  data-id="cbb469e" data-element_type="column">
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                      <div className="elementor-element elementor-element-79c4e46 elementor-widget elementor-widget-text-editor"
                                          data-id="79c4e46" data-element_type="widget" data-widget_type="text-editor.default">
                                          <div className="elementor-widget-container">
                                              <h3 className="headingSix">Privacy Policy</h3>
                                             <h4>1. General</h4> 
  <p>if you are visiting the site from outside the United States, be advised that your personal data will be processed by mobileinternetcabledeals.com in the United States, where data protection and privacy standards may differ from similar regulations in other countries. By using the Web site, you consent to any transfer of information outside of your country if you are outside the United States. Furthermore, by using the Website, you agree mobileinternetcabledeals.com use your personal information to contact you electronically, in writing or otherwise to provide notices relating to your use of the Website and to provide information about products and services offered by mobileinternetcabledeals.com. If you do not wish to receive future promotional materials from us, you can indicate your preference by following the unsubscribe instructions at the end of such communications from us.
  </p>
  <h4>2. Information Gathering / Data processing</h4>
  <p>We will never collect sensitive information about you without your explicit consent. When you register to become a client with mobileinternetcabledeals.com completing and submitting a form on the website, an account will be created automatically. The registration process will collect your name, address, phone number, email address and information about the location and demographics. When you visit the website mobileinternetcabledeals.com, our web servers may use technology to track the patterns of behavior of visitors to our site by collecting number of visits, average time spent, pages viewed, domain name used to access Internet and come to visit website. Furthermore, We use information as mentioned, Your email address, your name and your contact number is just used for communication between you and us, Your IP address is tracked and used for the purpose fraud prevention and your card details and billing information is only disclose to our merchant gateway and your financial institution like bank or card issuer.
  </p>
  <h4>3. Cookies and Log Files</h4>
  <p>Cookies may be used on some pages of our site. "Cookies" are small text files placed on your hard drive that helps us provide a more personalized site experience. For example, a cookie can be used to store registration information in an area of the site so that a user does not have to re-enter subsequent visits to that area. Policy is mobileinternetcabledeals.com use cookies to facilitate navigation of websites easier for visitors and to facilitate efficient registration procedures.If you are concerned about cookies, most browsers permit individuals to decline cookies. In most cases, a visitor may refuse a cookie and still fully navigate our website, however other features on the website may be impaired. After completion of the visit to our site, you can always delete the cookie from your system if desired.In order to properly manage our website may anonymously log information on our operational systems, and identify categories of visitors by items such as domains and browser types. These statistics are presented in the aggregate to our webmasters. This is to ensure that our website presents the best experience for our visitors and is an effective information source.
  </p>
  <h4>4. Your Acceptance of These Terms</h4>
  <p>By accessing the Website, you are agreeing to the Privacy Policy mobileinternetcabledeals.com. If you do not agree with this Privacy Policy, please do not access the Website. You should visit this page periodically, as we may, from time to time, we will update this Privacy Policy and such changes effective immediately upon posting on the Site without notice to you. Your continued access to the Website following the posting of changes to this Privacy Policy will be considered acceptance of those changes for you. We, however, use personal information only in accordance with the version of this privacy policy under which the information was provided by you.
  </p>
  <h4>5. Privacy</h4>
  <p>We may update this privacy statement in paragraph reflect changes in our information practices. If we make any changes matter if what you know by email (to the email address specified in your account) or by means of a notice on this Site before the change is Effective Right. We encourage you to check this page periodically for updated information on Privacy Practices.
  </p>
  <h4>6. Security</h4>
  <p>mobileinternetcabledeals.com has implemented generally accepted standards of technology and operational security to protect personally identifiable information from loss, misuse, alteration or destruction. All employees follow security policies across the network. Only authorized personnel access to personally identifiable information and these employees have agreed to ensure confidentiality of this information.
  </p>
  <h4>7. Children</h4>
  <p>We understand the importance of protecting children's privacy, especially in an online environment. mobileinternetcabledeals.com site covered by this privacy statement are not intentionally designed for or directed at children 13 years old or younger. Policy is not mobileinternetcabledeals.com knowingly collect or maintains information from children under 13 years
  </p>
  <h4>8. Limits on Our Abilities</h4>
  <p>Although your privacy is very important to us, due to legal and technical environment, we cannot guarantee that your personally identifiable information will not be shared with third parties in ways not described in this policy. Additionally, we can (and you authorize us to) disclose any information about you to private entities, law enforcement or other government officials as we, in our sole discretion, believe necessary or appropriate to address or resolve questions or problems.
  </p>
  <h4>9. Complaints Procedure</h4>
  <p>If you have a complaint regarding any aspect of our service, please email us. Please indicate clearly the nature of the complaint. It is also given a contact name that will keep you informed of the progress of any complaint. mobileinternetcabledeals.com may reserve the right to change this online privacy policy from time to time at its discretion. Changes will be posted on the website privacy policy and will be effective upon posting. Continued use of mobileinternetcabledeals.com after changes in this privacy policy are published online is considered acceptance of those changes. It is your responsibility to oversee privacy policy to determine if such changes have been posted. This privacy policy is subject and all applicable privacy laws.</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </section>
                  </div >
              </div >
              </div>
              <div
            data-elementor-type="footer"
            data-elementor-id="37142"
            className="elementor elementor-37142 elementor-location-footer"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-fee5260 elementor-section-boxed elementor-section-height-default elementor-section-height-default parallax_section_no qode_elementor_container_no"
              data-id="fee5260"
              data-element_type="section"
              data-settings='{"background_background":"gradient"}'
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-5411ae1"
                  data-id="5411ae1"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <section
                      className="elementor-section elementor-inner-section elementor-element elementor-element-a4458e1 elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default parallax_section_no qode_elementor_container_no"
                      data-id="a4458e1"
                      data-element_type="section"
                      id="stickyCTA"
                      data-settings='{"background_background":"classNameic"}'
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5dd6475"
                          data-id="5dd6475"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-4382977 elementor-widget elementor-widget-heading"
                              data-id="4382977"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">
                                  {" "}
                                  Internet® <br />
                                  Mobile | TV | Phone!
                                </h2>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c757507"
                          data-id="c757507"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-c5530c8 elementor-widget__width-auto elementor-widget-mobile__width-inherit elementor-widget elementor-widget-heading"
                              data-id="c5530c8"
                              data-element_type="widget"
                              data-widget_type="heading.default"
                            >
                              <div className="elementor-widget-container">
                                <h6 className="elementor-heading-title elementor-size-default">
                                  <a   href={tfn === null ? "tel:+18552000985" : this.getTfn(tfn)}>  {tfn === null ? "(855) 200-0985" : this.getTfnFormat(tfn)}</a>
                                </h6>{" "}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-de6bb48 elementor-widget__width-auto elementor-mobile-align-center elementor-widget-mobile__width-inherit elementor-widget elementor-widget-button"
                              data-id="de6bb48"
                              data-element_type="widget"
                              data-widget_type="button.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-button-wrapper">
                                  <a
                                   onClick={this.handleClick}
                                   href={tfn === null ? "tel:+18552000985" : this.getTfn(tfn)}
                                    className="elementor-button-link elementor-button elementor-size-xs"
                                    role="button"
                                  >
                                    <span className="elementor-button-content-wrapper">
                                      <span className="elementor-button-text">
                                        Call Us Get Started
                                      </span>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <div
                      className="elementor-element elementor-element-63f5d3c elementor-widget elementor-widget-text-editor"
                      data-id="63f5d3c"
                      data-element_type="widget"
                      data-widget_type="text-editor.default"
                    >
                      <div className="elementor-widget-container">
                        <p>
                          {" "}
                          Internet® + TV Select + Voice: Limited time offer;
                          subject to change; valid to qualified residential
                          customers who have not subscribed to any services
                          within the previous 30 days and who have no
                          outstanding obligation to Charter. TV® Select
                          promotion price is starting at $49.99/mo; standard rates apply
                          after yr. 1. Internet® promotion price is starting at $49.99/mo;
                          standard rates apply after yr. 1. Voice®: Price starting at
                          $14.99/mo when bundled. Taxes, fees and surcharges
                          (broadcast surcharge up to starting at $21.00/mo) extra and
                          subject to change during and after the promotional
                          period; installation/network activation, equipment and
                          additional services are extra. General Terms:
                          INTERNET: Speed based on wired connection and may vary
                          by address. Wireless speeds may vary. TV: TV equipment
                          required; charges may apply. Channel and HD
                          programming availability based on level of service.
                          Account credentials may be required to stream some TV
                          content online. HOME PHONE: Unlimited calling includes
                          calls within the U.S., Canada, Mexico, Puerto Rico,
                          Guam, the Virgin Islands and more. Services subject to
                          all applicable service terms and conditions, subject
                          to change. Services not available in all areas.
                          Restrictions apply. Enter your address to determine
                          availability. ©2022 Charter Communications.
                        </p>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-inner-section elementor-element elementor-element-aa90d0f elementor-section-content-middle elementor-section-boxed elementor-section-height-default elementor-section-height-default parallax_section_no qode_elementor_container_no"
              data-id="aa90d0f"
              data-element_type="section"
              data-settings='{"background_background":"classNameic"}'
            >
              <div className="elementor-container elementor-column-gap-default">
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-2ec072d"
                  data-id="2ec072d"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-a4ab7e3 elementor-widget elementor-widget-theme-site-logo elementor-widget-image"
                      data-id="a4ab7e3"
                      data-element_type="widget"
                      data-widget_type="theme-site-logo.default"
                    >
                      <div className="elementor-widget-container">
                        <a href="/">
                         
                          <p>Linking Flux Company</p>
                          <p>Authorized Reseller of TV, Internet & Phone Services</p>
                        </a>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-3825041 elementor-widget__width-auto elementor-fixed elementor-hidden-mobile elementor-invisible elementor-widget elementor-widget-image"
                      data-id="3825041"
                      data-element_type="widget"
                      data-settings='{"_position":"fixed","_animation":"fadeInUp"}'
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <a href="/">
                          <img
                            width="508"
                            height="924"
                            // src={Logo}
                            className="attachment-full size-full"
                            alt="Connect Now"
                            loading="lazy"
                            sizes="(max-width: 508px) 100vw, 508px"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-826ac7b"
                  data-id="826ac7b"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-3024b4a copyright elementor-widget elementor-widget-heading"
                      data-id="3024b4a"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-heading-title elementor-size-default">
                          <span>625 Kenmoor Ave SE Suite 301</span>{" "}
                          <span>Grand Rapids, MI 49546</span>
                          <br />  <br />© 2020-2022 by Linking Flux LLC. All Rights Reserved.
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-46f3f25"
                  data-id="46f3f25"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-2ae9d54 elementor-widget elementor-widget-heading"
                      data-id="2ae9d54"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <div className="elementor-heading-title elementor-size-default">
                          <a href="/privacy-policy/">Privacy Policy</a>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
              </body>
              </>

        )
    }
}



export default withRouter(Privacy)
