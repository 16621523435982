import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { routes } from "../src/Routes/Routes";
import './assests/css/style1.css'
import './assests/css/style2.css'
import './assests/css/style3.css'
import './assests/css/style4.css'
import './assests/css/style5.css'
import './assests/css/style6.css'
import './assests/css/style7.css'
import './assests/css/nav.css'
import './assests/css/style8.css'
import './assests/css/mamoon.css'



class App extends Component {
  // componentDidMount() {
   
  //   const script = document.createElement("script");
  //   script.src = "https://www.googletagmanager.com/gtag/js?id=AW-606595014";
   
  //   document.body.appendChild(script)

  // }
 

  render() {
    return (
      <BrowserRouter>
          <Switch>
            {
              routes.map((obj) => {
                return (
                  <Route exact path={obj.path} component={obj.component} />
                )
              })
            }
            {/* <Redirect from="/privacy" to="/privacy" />  */}
          </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
